import React from 'react';
import { Carousel } from 'antd';

export default function Testimonials() {
  const testimonials = [
    {
      name: 'Jack',
      company: ' WAQ Production',
      title: 'Set up social media accounts and social media management',
      review:
        "I highly recommend Adrian for social media marketing services! He is incredibly efficient and professional, not only completing my tasks but also offering valuable recommendations to enhance my business's social media strategy. I can’t emphasize enough how much he has helped me in such a short time, and I look forward to a long-term partnership!",
    },
    {
      name: 'Selixa',
      company: 'Tuiga Samoa & Pacific Portraits',
      title: 'Vamp up social media and create new website',
      review:
        'Adrian was great to work with - he met my brief in detail, a gave a few additional tips that helped us with our social media!',
    },
  ];

  const renderTestimonials = () => {
    return testimonials.map((testimonial, index) => {
      return (
        <div className='px-4 sm:px-28 md:px-72 select-none hover:cursor-pointer'>
          <span
            key={index}
            className='w-full sm:w-5/12 rounded-2xl text-left p-5'
          >
            <p className='text-lg p-2'>"{testimonial.review}"</p>
            <p className='text-4xl font-bold p-2'>{testimonial.name}</p>
            <p className='text-lg pl-2'>{testimonial.company}</p>
          </span>
        </div>
      );
    });
  };

  return (
    <Carousel
      autoplay
      draggable={true}
      autoplaySpeed={5500}
      speed={3000}
      easing='ease-out'
    >
      {renderTestimonials()}
    </Carousel>
  );
}
