import './App.css';
import React from 'react';

// Profile collage image and icons
import profileCollage from './assets/profile_collage.jpg';
import facebookIcon from './assets/facebook.png';
import instagramIcon from './assets/instagram.png';
import whatsappIcon from './assets/whatsapp.png';

// Client logos
import emuDreaming from './assets/client_logos/emu_dreaming.png';
import classicCountry from './assets/client_logos/classic_country.png';
import pacific_portraits from './assets/client_logos/pacific_portraits.png';

// Internal components
import Header from './components/Header';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';

function App() {
  const services = [
    {
      key: 1,
      title: 'Social Media Management',
      question: 'Overwhelmed by managing multiple social media accounts?',
      description:
        'We handle content creation, posting, and engagement across your social platforms to keep your brand active and consistent.',
    },
    {
      key: 2,
      title: 'Social Media Strategy',
      question: 'Struggling to Find the Right Social Media Strategy?',
      description:
        'We develop customised strategies that align with your business goals, helping you reach the right audience with impactful content.',
    },
    {
      key: 3,
      title: 'Facebook & Instagram Ads Management',
      question: 'Not Seeing Results from Your Ads?',
      description:
        'Our team optimises your ad campaigns for maximum reach, engagement, and conversions while keeping costs in check.',
    },
    {
      key: 4,
      title: '1:1 Consultation',
      question: 'Need Expert Guidance to Grow Your Online Presence?',
      description:
        'Our personalised 1:1 consultation helps you navigate social media, ads, and digital strategy to achieve your business goals with confidence.',
    },
  ];
  const testimonials = [
    {
      name: 'Jack',
      company: ' WAQ Production',
      title: 'Set up social media accounts and social media management',
      review:
        "I highly recommend Adrian for social media marketing services! He is incredibly efficient and professional, not only completing my tasks but also offering valuable recommendations to enhance my business's social media strategy. I can’t emphasize enough how much he has helped me in such a short time, and I look forward to a long-term partnership!",
    },
    {
      name: 'Selixa',
      company: 'Tuiga Samoa & Pacific Portraits',
      title: 'Vamp up social media and create new website',
      review:
        'Adrian was great to work with - he met my brief in detail, a gave a few additional tips that helped us with our social media!',
    },
  ];

  const renderTestimonials = () => {
    return testimonials.map((testimonial, index) => {
      return (
        <div key={index} className='w-full sm:w-5/12 rounded-2xl text-left p-5'>
          <p className='text-lg p-2'>"{testimonial.review}"</p>
          <p className='text-4xl font-bold p-2'>{testimonial.name}</p>
          <p className='text-lg pl-2'>{testimonial.company}</p>
        </div>
      );
    });
  };

  const displayServices = () => {
    return services.map((service) => {
      const animateTimer = `animate-[slideUp_${
        service.key % 2 === 0 ? '1' : '1.2'
      }s] `;

      return (
        <div
          key={service.key}
          className={'relative w-full lg:w-[23%] py-12 ' + animateTimer}
        >
          <span className='inline-block align-top w-1/12 text-right px-2 pr-7 text-xl font-extralight border-r-2'>
            {service.key}
          </span>
          <span className='inline-block w-10/12 px-4 text-left'>
            <h2 className='font-extrabold text-lg'>{service.title}</h2>
            <h3 className='py-4 font-semibold'>{service.question}</h3>
            <p className='pb-14'>{service.description}</p>
            <button className='home-info-button absolute bottom-5 border-white border-2 rounded-3xl py-2 mt-4 px-6 hover:scale-105 transition-transform duration-300'>
              <Link id='home-info-button' to='/services'>
                Info
              </Link>
            </button>
          </span>
        </div>
      );
    });
  };

  return (
    <div className='App'>
      <Header />
      {/* About us */}
      <div className='bg-white text-black px-6 py-6 md:px-16 flex flex-wrap items-center justify-center'>
        <span className='w-full sm:w-1/2 text-left neuton-regular'>
          {/* first 2 p tags moves from out of screen from left and moves in to the right into screen*/}
          <div className='text-5xl animate-[slideRight_0.8s_ease-out]'>
            <h1>Grow Your Brand</h1>
            <h1>Dominate Social Media</h1>
          </div>
          <h2 className='text-lg py-7 animate-[slideRight_1s_ease-out]'>
            Your dedicated partner in navigating the social landscape.
          </h2>
          <div className='animate-[fadeIn_1.5s_ease-out]'>
            <button className='text-base border-4 border-black rounded-3xl py-3 px-8 hover:scale-105 transition-transform duration-300'>
              <Link id='home-hero-services-button' to='/services'>
                SERVICES {'>'}
              </Link>
            </button>
            <button className='ml-4 text-base border-4 border-black rounded-3xl py-3 px-8 hover:scale-105 transition-transform duration-300'>
              <Link id='home-hero-about-button' to='/about-us'>
                ABOUT US {'>'}
              </Link>
            </button>
            <div className='mt-4 flex gap-4'>
              <a
                className='home-hero-instagram-button'
                href='https://www.instagram.com/atoz.marketing.official'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='inline-block ml-2 hover:cursor-pointer'
                  width={30}
                  src={instagramIcon}
                  alt='instagram icon'
                />
              </a>
              <a
                className='home-hero-facebook-button'
                href='https://www.facebook.com/atoz.marketing.official'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='inline-block home-hero-facebook-icon hover:cursor-pointer'
                  width={30}
                  src={facebookIcon}
                  alt='facebook icon'
                />
              </a>
              <a
                className='home-hero-whatsapp-button'
                href='https://wa.me/614493323312'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='inline-block home-hero-whatsapp-icon hover:cursor-pointer'
                  width={30}
                  src={whatsappIcon}
                  alt='whatsapp icon'
                />
              </a>
            </div>
          </div>
        </span>
        <span className='w-full sm:w-1/2'>
          <img src={profileCollage} alt='profileImage' />
        </span>
      </div>

      {/* About us - 2 */}
      <div className='home-about-us flex flex-wrap text-left justify-start sm:text-center sm:justify-center gap-5 px-6 py-16 md:px-16 neuton-regular'>
        <h1 className='text-3xl sm:text-5xl font-bold'>
          Your Social Media Allies
        </h1>
        <p className='font-bold'>
          Hi there, welcome to A to Z Marketing! 👋 We're a boutique social
          media marketing agency made up of a group of friends who are
          passionate about helping businesses thrive. Operating across the APAC
          region, we specialize in partnering with small businesses to enhance
          their online presence.
        </p>
        <p>
          We believe social media marketing should be accessible and
          hassle-free. That's why we provide clear, actionable strategies for
          those who may lack the time, expertise, or resources to handle it on
          their own.
        </p>
        <p>
          Our close-knit team truly cares about the impact your social media
          presence can make. We offer a variety of services tailored to your
          unique journey—whether you want to manage things in-house or
          collaborate with our experts.
        </p>
        <p>
          Let's work together to elevate your brand and connect with your
          audience!
        </p>
      </div>

      {/* Services*/}
      <div className='neuton-regular'>
        <h1 className='home-services text-center text-5xl font-bold'>
          Our Services
        </h1>
        <div className='pb-16 flex flex-wrap justify-center'>
          {displayServices()}
        </div>
      </div>

      {/* Dedicated team */}
      <div className='home-dedicated-team flex flex-wrap gap-x-5 neuton-regular text-left px-6 py-16 md:px-16 text-black bg-white'>
        <div className='flex items-center justify-center w-full sm:w-1/2'>
          <img
            className='rounded-[10%] w-full sm:p-8'
            src={profileCollage}
            alt='Profile collage of 2 founders'
          />
        </div>
        <div className='py-16 w-full sm:w-[45%]'>
          <h1 className='text-3xl sm:text-5xl font-bold'>Our dedicated team</h1>
          <h2 className='text-2xl py-4'>
            A Passionate Team United by a Shared Vision for Your Business
            Success
          </h2>
          <p>
            We are a committed group that genuinely cares about helping you
            achieve your goals. With a collaborative approach and a deep
            understanding of your needs, we work together to create solutions
            that drive your business forward.
          </p>
          <button className='mt-12 text-base border-4 border-black rounded-3xl py-3 px-8 hover:scale-105 transition-transform duration-300'>
            <Link id='home-dedicated-team-button' to='/about-us'>
              About Us
            </Link>
          </button>
        </div>
      </div>

      {/* Results */}
      <div className='home-results neuton-regular px-6 md:mx-16 py-16'>
        <h1 className='text-2xl pb-4 font-extrabold text-center'>
          Results That Speak For Themselves
        </h1>
        <h2 className='text-2xl'>
          Here are some of the impressive outcomes we've achieved for our
          clients.
        </h2>
        <div className='flex flex-wrap pt-8 justify-center'>
          <div className='w-full sm:w-1/3 flex items-center p-4'>
            <h3 className='inline-block w-1/2 text-6xl sm:text-7xl mr-4 font-extrabold'>
              5m+
            </h3>
            <p className='inline-block w-1/2 text-left text-lg font-light'>
              Over 5 million accounts reached for our clients
            </p>
          </div>
          <div className='w-full sm:w-1/3 flex items-center p-4'>
            <h3 className='inline-block w-1/2 text-6xl sm:text-7xl mr-4 font-extrabold'>
              100+
            </h3>
            <p className='inline-block w-1/2 text-left text-lg font-light'>
              Over 100 hours saved for clients per month
            </p>
          </div>
          <div className='w-full sm:w-1/3 flex items-center p-4'>
            <h3 className='inline-block w-1/2 text-6xl sm:text-7xl mr-4 font-extrabold'>
              200%
            </h3>
            <p className='inline-block w-1/2 text-left text-lg font-light'>
              Over 200% average increase in reach, spread across all clients
            </p>
          </div>
          <div className='w-full pt-8'>
            <h1 className='text-2xl pb-4 font-extrabold text-center'>
              A Few of the Incredible Brands We've Collaborated With
            </h1>
          </div>
          <div className='flex flex-wrap justify-center items-end text-lg'>
            <div>
              <img
                className='h-40 px-8 pb-4'
                src={emuDreaming}
                alt='emu dreaming'
              />
              <p>Emu Dreaming</p>
            </div>
            <div>
              <img
                className='h-40 px-8 pb-4'
                src={classicCountry}
                alt='classic country'
              />
              <p>Classic Country</p>
            </div>
            <div>
              <img
                className='h-48 px-8 pb-4'
                src={pacific_portraits}
                alt='pacific portraits'
              />
              <p>Pacific Portraits</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div
        id='home-testimonials'
        className='neuton-regular text-5xl text-black bg-[#F5F5DC] py-12 px-6 md:px-32 flex flex-col items-center justify-center'
      >
        <h1 className='text-3xl font-extrabold text-center'>Testimonials</h1>
        <div className='pt-4 flex flex-wrap gap-4 justify-center'>
          {renderTestimonials()}
        </div>
      </div>
      {/* <div className='bg-[#F5F5DC]'>
        <Testimonials />
      </div> */}

      {/* Clients & Careers */}
      <div
        id='home-services'
        className='neuton-regular flex text-4xl justify-center bg-black'
      >
        <div className='flex flex-wrap gap-0 bg-white text-black m-16 text-left p-5 rounded-xl'>
          <div className='w-full sm:w-1/2 sm:p-14 text-left border-black border-b-2 sm:border-r-2 sm:rounded-br-2xl'>
            <h1>Our Services</h1>
            <p className='text-lg'>
              Reach out to us for more information or to schedule your free
              Discovery Call!
            </p>
            <a
              id='home-footer-services-button'
              className='py-4 text-2xl block hover:scale-105 transition-transform duration-300'
              href='https://forms.gle/oyJPQDbQP4JVZxuP6'
              target='_blank'
              rel='noreferrer'
            >
              👉
            </a>
          </div>
          <div className='w-full sm:w-1/2 sm:p-14 text-left border-black sm:border-t-2'>
            <h1>Get in touch</h1>
            <p className='text-lg'>
              We'd love to hear from you! Reach out to explore your options or
              book a complimentary Consultation Call.
            </p>
            <a
              id='home-footer-consultation-button'
              className='py-4 text-2xl block hover:scale-105 transition-transform duration-300'
              href='https://forms.gle/8Bi8JHhbt1ZoE1BS9'
              target='_blank'
              rel='noreferrer'
            >
              👉
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
