import React from 'react';

// Internal components
import Header from '../components/Header';
import Footer from '../components/Footer';

// Assets
import profileCollage from '../assets/profile_collage.jpg';
import Testimonials from '../components/Testimonials';

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className='neuton-regular px-4 sm:px-16'>
        <div className='text-center py-16'>
          <h1 className='font-bold text-4xl'>
            Welcome to your personal Marketing Crew! 👋
          </h1>
          <p className='text-xl py-8'>
            We're excited to introduce the talented individuals behind our
            success!
          </p>
          <p className='text-xl'>
            What began with one passionate marketer has grown into a vibrant
            team of social media aficionados. With a combined experience of over
            five years, we bring creativity, enthusiasm, and a fresh perspective
            to help businesses shine online.
          </p>
        </div>
        <div className='flex flex-wrap gap-x-5 neuton-regular text-left px-6 md:px-16 text-black bg-white'>
          <div className='flex items-center justify-center w-full sm:w-1/2'>
            <img
              className='rounded-[10%] w-full sm:p-8'
              src={profileCollage}
              alt='Profile collage of 2 founders'
            />
          </div>
          <div className='py-16 w-full sm:w-[45%]'>
            <ul className='list-disc'>
              <li>
                <h2 className='text-2xl sm:text-3xl font-bold'>
                  A Partnership Like No Other
                </h2>
                <p className='text-xl py-4'>
                  A Passionate Team United by a Shared Vision for Your Business
                  Success
                </p>
              </li>
              <li>
                <h2 className='text-2xl sm:text-3xl font-bold'>
                  Innovative Storytellers
                </h2>
                <p className='text-xl py-4'>
                  Your message deserves to stand out! We craft engaging content
                  that captivates audiences, steering clear of dull templates to
                  ensure your brand's voice is heard loud and clear.
                </p>
              </li>
              <li>
                <h2 className='text-2xl sm:text-3xl font-bold'>
                  Passion-Driven Results
                </h2>
                <p className='text-xl py-4'>
                  Social media should be enjoyable! We leverage our expertise
                  and excitement to elevate your brand, making connections that
                  matter. Our love for what we do is evident in every project we
                  undertake!
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className='text-center pt-16 pb-4'>
          <h1 className='text-4xl font-extrabold'>Client Testimonials</h1>
          <h2 className='text-xl'>Outcomes That Deserve To Be Celebrated</h2>
        </div>
      </div>
      <Testimonials />
      <Footer />
    </>
  );
};

export default AboutUs;
