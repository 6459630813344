import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Services = () => {
  const services = [
    {
      title: 'Social Media Management',
      description:
        'Struggling to keep up with multiple social media accounts? We handle everything from content creation to community engagement, helping your brand stay active and relevant.',
      idealFor:
        'Businesses looking for a consistent and engaging social media presence.',
    },
    {
      title: 'Social Media Strategy',
      description:
        'Unsure how to effectively reach your audience? We provide a clear roadmap tailored to your goals, ensuring your social media efforts drive results.',
      idealFor:
        'Brands seeking a structured approach to enhance their social media efforts.',
    },
    {
      title: 'Facebook & Instagram Ads Management',
      description:
        'Not getting the results you want from your ads? Our expertise ensures your campaigns are optimized for maximum reach and engagement.',
      idealFor:
        'Companies aiming to boost their ad performance and engagement.',
    },
    {
      title: '1:1 Consultation',
      description:
        'Need tailored guidance for your online strategy? Our consultations provide valuable insights and personalized support to help you achieve your goals.',
      idealFor:
        'Individuals or businesses looking for customized strategic advice.',
    },
    {
      title: 'Professional Photography & Editing',
      description:
        'Want to stand out with high-quality visuals? We deliver stunning photography and editing services that enhance your brand’s appeal.',
      idealFor:
        'Brands wanting to elevate their visual content for better engagement.',
    },
    {
      title: 'Landing Page Creation',
      description:
        'Struggling to convert visitors on your landing pages? We design effective, high-converting pages that capture leads and drive sales.',
      idealFor:
        'Businesses without a landing page or website looking to establish an online presence.',
    },
  ];

  const renderServices = () => {
    return services.map((service, index) => {
      return (
        <div
          key={index}
          className='flex flex-col md:flex-row items-center w-full sm:w-[28%] justify-center py-12 border-2 border-black rounded-2xl'
        >
          <div className='px-8'>
            <h3 className='font-extrabold text-2xl sm:text-3xl'>
              {service.title}
            </h3>
            <p className='text-lg sm:text-xl my-4'>{service.description}</p>
            <p className='text-lg sm:text-xl'>
              <strong>Ideal for: </strong>
              <i>{service.idealFor}</i>
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Header />
      <div className='neuton-regular px-6 sm:px-16'>
        <div className='flex flex-col text-center py-24 justify-center'>
          <h1 className='font-extrabold text-3xl sm:text-4xl text-center'>
            Elevate Your Social Media Game
          </h1>
          <h2 className='text-2xl sm:text-3xl'>
            Discover the services we offer to boost your online presence!
          </h2>
        </div>
        <div className='flex flex-wrap gap-14 justify-center'>
          {renderServices()}
        </div>
        <div className='text-center px-4 py-16 sm:p-16'>
          <h1 className='text-4xl pb-8'>
            Unsure which service suits your needs? Get in touch with us!
          </h1>
          <div className='inline-block py-4 px-6 hover:scale-105 transition-transform duration-300 border-4 border-black rounded-2xl font-bold'>
            <a
              id='services-get-in-touch'
              className='text-2xl'
              href='https://forms.gle/oyJPQDbQP4JVZxuP6'
              target='_blank'
              rel='noreferrer'
            >
              GET IN TOUCH
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
