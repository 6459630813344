import React, { useState, useLayoutEffect } from 'react';

// Assets
import menu from '../assets/menu.png';
import logoBlack from '../assets/atoz_logos/logo_black.png';

// External components
import { Drawer } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const showDrawer = () => {
    setNavbarOpen(true);
  };
  const onClose = () => {
    setNavbarOpen(false);
  };

  const links = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/services',
      text: 'Services',
    },
    {
      link: '/about-us',
      text: 'About Us',
    },
  ];

  const location = useLocation();

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  const renderLinks = () => {
    return links.map((link, index) => {
      const activeStyle =
        window.location.pathname === link.link
          ? 'text-[#DAA520] font-bold '
          : '';
      return (
        <li key={index} className={'header' + link.link}>
          <NavLink to={link.link} className={activeStyle}>
            <p className='hover:scale-105 transition-transform duration-50'>
              {link.text}
            </p>
          </NavLink>
        </li>
      );
    });
  };

  return (
    <header className='header neuton-regular text-white flex justify-between items-center w-full bg-transparent px-6 md:px-16 py-6'>
      <div className='flex items-center'>
        <NavLink
          className='header-logo'
          to='/'
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <img
            className='inline-block'
            width={49}
            src={logoBlack}
            alt='AtoZ Marketing logo'
          />
          <p className='inline-block text-2xl sm:text-3xl'>AtoZ Marketing</p>
        </NavLink>
      </div>
      <ul className='hidden sm:flex gap-x-5 text-xl'>{renderLinks()}</ul>
      <img
        className='inline-block sm:hidden hover:scale-105 transition-transform duration-300'
        width={30}
        src={menu}
        alt='menu'
        onClick={() => showDrawer()}
      />
      <Drawer
        className='text-xl font-extrabold'
        placement='left'
        open={navbarOpen}
        closeable={true}
        width='77%'
        onClose={onClose}
      >
        <ul className='flex flex-wrap flex-col gap-4'>{renderLinks()}</ul>
      </Drawer>
    </header>
  );
};

export default Header;
